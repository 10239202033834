<template>
  <v-dialog  v-model="dialog" max-width="500px" persistent transition="dialog-bottom-transition">
    <v-card flat style="overflow: hidden">
      <v-toolbar dark>
        <v-toolbar-title class="text-h5">
          {{ formTitle }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-4 px-4">
          <v-row >
            <v-col  cols="12">
              <v-tabs
                value="id"
                v-model="lenguageTab"
                background-color="transparent"
                color="basil"
                grow
              >
                <v-tab v-for="item in language" :key="item.id">
                  {{ item.name }}
                </v-tab>
              </v-tabs>
            </v-col>
            <v-col cols="12" class="pa-0">
              <v-form ref="form" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" class="my-0 py-0">
                      <v-text-field
                        v-model="evenCategory[language[lenguageTab].id]"
                        :label="`Nombre de la categoria en ${
                          lenguageTab === 0 ? 'ESPAÑOL' : 'INGLÉS'
                        }`"
                        :placeholder="`Escribe un nombre de la categoria en ${
                          lenguageTab === 0 ? 'ESPAÑOL' : 'INGLÉS'
                        }`"
                        outlined
                        persistent-hint
                        :rules="[(v) => !!v || 'Nombre es requerido']"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-col>
          </v-row>
        <v-form> </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="py-1 px-4">
        <v-spacer></v-spacer>
        <v-btn class="ma-2" :loading="loading" color="error" @click="dialog = false">
          <v-icon left dark >mdi-close-circle</v-icon>
          Cancelar
        </v-btn>
        <v-btn :loading="loading" color="success" @click="aceptEventCategory()">
          <v-icon left dark>mdi-check-circle</v-icon>
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "formEventCategory",
  props: ["showDialog", "editedItem", "editedIndex", "currentCorp"],
  data() {
    return {
      language: [
        { id: "ES", name: "Español" },
        { id: "EN", name: "Inglés" },
      ],
      lenguageTab: 0,
      evenCategory: {
        id_eventcata: null,
        id_corp: null,
        ES: "",
        EN: "",
      },
      indexEvenCategory: -1,
      loading: false,
    };
  },
  computed: {
    formTitle() {
      return this.editedItem === -1 ? "Nueva Categoria del Evento" : "Categoria del Evento";
    },
    dialog: {
      get() {
        this.evenCategory = Object.assign({}, this.editedItem);
        return this.showDialog;
      },
      set() {
        this.clearDialog();
        this.$emit("closeForm");
      },
    },
  },
  methods: {
    clearDialog() {
      this.evenCategory = {
        id_eventcata: null,
        id_corp: null,
        ES: "",
        EN: "",
      };
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    saveEventCategory() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$root
          .post("/Admin/saveEventCategory", {
            id_corp: this.currentCorp,
            nameEs: this.evenCategory.ES,
            nameEn: this.evenCategory.EN,
          })
          .then((response) => {
            const info = response.data;
            if (info.success === "FALSE") {
              return this.$root.swalAlert("error", "Error al guardar la categoria del evento", info.Msg);
            }
            this.$root.swalAlert("success", "Se guardo la categoria del evento");
            this.$emit("reloadEventCategories");
            this.dialog = false
            })
          .catch((e) => {
            this.$root.swalAlert("error", "Error al guardar la categoria del evento", e.message);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    updateEventCategory() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$root
          .post("/Admin/updateEventCategory", {
            id_eventcata: this.evenCategory.id_eventcata,
            nameEs: this.evenCategory.ES,
            nameEn: this.evenCategory.EN,
          })
          .then((response) => {
            const info = response.data;
            if (info.success === "FALSE") {
              return this.$root.swalAlert("error", "Error al actualizar la categoria del evento", info.Msg);
            }
            this.$root.swalAlert("success", "Se actualizo la categoria del evento");
            this.$emit("reloadEventCategories");
            this.dialog = false
          })
          .catch((e) => {
            this.$root.swalAlert("error", "Error al actualizar la categoria del evento", e.message);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    aceptEventCategory() {
      this.editedIndex === -1 ? this.saveEventCategory() : this.updateEventCategory();
    },
  },
};
</script>
