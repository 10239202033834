<template>
  <v-container>
    <v-card>
      <v-card-title>
        <span class="text-h5">Tipos De Eventos</span>
        <v-spacer />
        <v-btn color="success" dark class="mb-2" fab @click="newEventategory">
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col v-if="$store.state.auth.user.admin === 1">
            <v-select v-model="currentIdCorp" dense outlined append-icon="mdi-database-outline" :loading="loading"
              :items="$store.state.auth.corps" :item-text="({ name, idcorp }) => `${name} (Corp ${idcorp})`"
              item-value="idcorp" label="Corporación seleccionada" >
            </v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="search" dense outlined append-icon="mdi-magnify" label="Search" single-line
              hide-details></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table :headers="headers" :items="eventsCatalogue" :items-per-page="10" :search="search"
              :loading="loading" loading-text="Loading... Please wait" class="row-pointer">
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon class="mr-2" color="primary" @click="editEventategory(item)">
                  mdi-pencil
                </v-icon>
                <v-icon color="error" @click="deleteEventategory(item)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <formEventCategory 
      v-if="dialogForm"
      :showDialog="dialogForm"
      :editedIndex="editedIndex"
      :editedItem="editedItem"
      :currentCorp="currentIdCorp"
      @closeForm = "closeForm()"
      @reloadEventCategories = "getEventCategories()"
    />
  </v-container>
</template>

<script>
import formEventCategory from '../../../components/configuration/eventCategories/formEventCategory.vue'

export default {
  name: "EventsTypes",
  components: {
    formEventCategory,
  },
  data() {
    return {
      currentIdCorp: null,
      search: "",
      eventsCatalogue: [],
      headers: [
        { text: "Id", value: "id_eventcata" },
        { text: "Nombre", value: "ES" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        id_eventcata: null,
        id_corp: null,
        ES: "",
        EN: "",
      },
      defaultItem: {
        id_eventcata: null,
        id_corp: null,
        ES: "",
        EN: "",
      },
      loading: false,
      dialogForm: false,
      language: [
        { id: "ES", name: "Español" },
        { id: "EN", name: "Inglés" },
      ],
    };
  },
  watch: {
    currentIdCorp: function (val) {
      this.$store.dispatch("auth/setSelectedCorp", val);
      this.getEventCategories();
    },
  },
  mounted() {
    console.log("EventsTypes.vue");
    this.currentIdCorp = this.$store.state.auth.user.id_corp;
  },
  methods: {
    getEventCategories() {
      this.loading = true;
      this.eventsCatalogue = [];
      this.$root
        .post("/Admin/getEventsCatalogue")
        .then((response) => {
          const info = response.data;
          if (info.success === "FALSE") {
            return this.$root.Toast("info", info.Msg);
          }
          this.eventsCatalogue = info.Data;
        })
        .catch((e) => {
          this.$root.swalAlert(
            "error",
            "Error al cargar las categorias de los eventos",
            e.message
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    newEventategory() {
      this.dialogForm = true;
    },
    editEventategory(item) {
      this.editedIndex = this.eventsCatalogue.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogForm = true;
    },
    deleteEventategory: async function (item) {
      let confirmSwal = await this.$swal({
        title: "¿Confirma la eliminación del registro?",
        text: `${item.id_eventcata} - ${item.ES}`,
        icon: "question",
        showDenyButton: true,
        showCancelButton: false,
        reverseButtons: true,
        confirmButtonText: "Si",
      });

      if (!confirmSwal.isConfirmed) return;
      this.$root
        .post("/Admin/deleteEventCategory", { id_eventcata: item.id_eventcata })
        .then((response) => {
          const info = response.data;
          if (info.success === "FALSE") {
            return this.$root.swalAlert(
              "error",
              "Error al eliminar la categoria",
              info.Msg
            );
          }
          this.getEventCategories();
        })
        .catch((e) => {
          this.$root.swalAlert(
            "error",
            "Error al eliminar la categoria",
            e.message
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeForm() {
      this.dialogForm = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
